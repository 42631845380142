import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServerService {
  private serverUrl = ""; 

  constructor(private http: HttpClient) {
    this.serverUrl = environment.serverUrl;
  }

  private async request(method: string, url: string, data?: any) {
    const result = this.http.request(method, url, {
      params: data,
      body: data,
      responseType: 'json',
      observe: 'body',
      withCredentials: true,
    });
    return new Promise((resolve, reject) => {
      result.subscribe(resolve, reject);
    });
  }

  public async getWeek(date, year, week) {
    return await this.request('GET', this.serverUrl + "/api/week", {"date": date, "year": year, "week": week});
  }

  public async saveWeek(weekData: any) {
    return await this.request('POST', this.serverUrl + "/api/week", weekData);
  }

  public async login(username, password) {
    return await this.request('POST', this.serverUrl + "/login/auth", {"username": username, "password": password});
  }

  public async getCurrentUser() {
    return await this.request('GET', this.serverUrl + "/user", {});
  }

  public async getTasks() {
    return await this.request('GET', this.serverUrl + "/api/tasks");
  }
  
  public async saveTasks(taskList) {
    return await this.request('POST', this.serverUrl + "/api/tasks", {"tasks": taskList});
  }

}
