<!-- Mon: {{weekData.days[0] | json}}
<br>
Sun: {{weekData.days[6] | json}} -->
<div style="margin-left: 20px" *ngIf="!loading">
	<div style="position: relative; margin-top:20px; z-index: 1;" >
        <h1 *ngIf="!loading">Hej {{user.fname}}</h1>

        <div class="dialog_field">
            <button (click)="change_week(-1)" [disabled]="editing || working"><=</button>
            <button (click)="change_week(1)" [disabled]="editing || working">=></button>
        </div>

        <section class="dialog_container dialog_content_float" style="resize:both;overflow: auto;">
            <fieldset class="dialog_field" [disabled]="!editing">
                <legend><input type="week" value="{{weekDate.getFullYear() + '-W' + pipe.transform(weekDate, 'ww')}}" (change)="select_date($event.target.valueAsDate)" [disabled]="editing || working"/></legend>
                <table style="width: 750px">
                    <thead>
                        <tr>
                            <th style="text-align: center; border-bottom: 1px solid black">Aktivitet</th>
                            <th style="  text-align: center;" *ngFor="let day of weekData.days"
                                [style.color]="day.holiday != null ? 'red': ''"
                                [style.min-width]="calc_column_width(day)" 
                                (click)="add_work_pass(day)">
                                {{day.name}}<br>{{day.date}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="text-align: right; border-right: 1px solid black">Start time</td>
                            <td style="text-align: center; border: 1px solid black" *ngFor="let day of weekData.days"
                            [style.background]="day.holiday?'rgba(255, 0, 0, 0.199)':''">
                                <div class="dialog_field_float">
                                    <input type="text" [(ngModel)]="work_pass.start"
                                        input-text
                                        [style.width]="set_input_width(day)"
                                        *ngFor="let work_pass of day.work"
                                        (change)="calc_total_time();">
                                </div>
                            </td>
                        </tr>

                        <tr *ngFor="let selectedTaskId of usedTasks; index as selectedTaskIndex" >
                            <td style="text-align: center; width: 150px; border-right: 1.5px solid black;  min-width: 200px">
                            <select (change)="change_task(selectedTaskIndex, $event);"
                                (value)="usedTasks[selectedTaskIndex]"
                                style="min-width:95%;"
                                [style]="selectedTaskId == -1 ? {'font-style': 'italic'}:{}">
                                <option *ngFor="let task of taskList | filterTasks: selectedTaskId: this" 
                                    [value]="task.id" 
                                    [selected]="task.id == selectedTaskId">
                                    {{task.name}}
                                </option>
                            </select>
                            </td>
                            <td style="text-align: center;border: 1px solid black" *ngFor="let day of weekData.days; index as dayIndex"
                                    [style.background]="day.holiday?'rgba(255, 0, 0, 0.199)':''">
                                <div class="dialog_field_float">
                                    <input type="number" 
                                        [(ngModel)]="work_pass.activity[selectedTaskIndex].time"
                                        [style.width]="set_input_width(day)"
                                        *ngFor="let work_pass of day.work"
                                        (change)="calc_total_time();"
                                        max=24 min=0 step="0.25"
                                        [disabled]="!is_active(usedTasks[selectedTaskIndex])">
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td style="text-align: right; border-top: 1px solid black">End time</td>
                            <td style="text-align: center; border: 1px solid black" 
                                    *ngFor="let day of weekData.days"
                                    [style.background]="day.holiday?'rgba(255, 0, 0, 0.199)':''">
                                <div class="dialog_field_float">
                                    <input 
                                        class="dialog_field_float" 
                                        type="text" 
                                        [(ngModel)]="work_pass.end"
                                        [style.width]="set_input_width(day)"
                                        (change)="calc_total_time();"
                                        *ngFor="let work_pass of day.work">
                                </div>
                            </td>
                        </tr>                    
                        <tr>
                            <td style="text-align: right;">Break</td>
                            <td style="text-align: center; border: 1px solid black" *ngFor="let day of weekData.days"
                                    [style.background]="day.holiday?'rgba(255, 0, 0, 0.199)':''">
                                <div class="dialog_field_float">
                                    <input 
                                        disabled 
                                        class="dialog_field_float" 
                                        type="text" 
                                        [ngModel]="work_pass.break" 
                                        [style.width]="set_input_width(day)"
                                        *ngFor="let work_pass of day.work">
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: right;">Total Time</td>
                            <td style="text-align: center; border: 1px solid black" *ngFor="let day of weekData.days">
                                <input 
                                    disabled 
                                    class="dialog_field_float" 
                                    type="text" 
                                    [ngModel]="day.total_time" 
                                    style="width: 95%">
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: right;  border-top: 1px solid black">Flex Time ({{weekData.flex_in}})</td>
                            <td style="text-align: center; border: 1px solid black" *ngFor="let day of weekData.days">
                                {{day.flex}}
                            </td>
                            <td style="text-align: right; border-top: 1px solid black">({{weekData.flex_out}})</td>
                        </tr>
                    </tbody>
                </table>
            </fieldset>

            <div class="dialog_field">
                <button (click)="edit()" *ngIf="!working" [disabled]="editing">Editera</button>
                <button (click)="save()" *ngIf="1working" [disabled]="!editing">Spara</button>
                <button (click)="cancel()" *ngIf="editing && !working">Avbryt</button>
                <button (click)="export()" *ngIf="!editing && !working">Export</button>
            </div>
        </section>


    </div>
</div>
