
<mat-sidenav-container>
  <mat-sidenav [mode]="'side'"
  [opened]="true" position="start" [fixedInViewport]="true"
  fixedTopGap="0">
    <mat-nav-list>

      <a mat-list-item [routerLink]="'/week'"> Tidsrapportering </a>
      <a mat-list-item [routerLink]="'/tasks'"> Aktiviteter </a>
      <a mat-list-item [routerLink]="'/'"> Rapporter </a>

    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <div style="height: 88vh;">

      <router-outlet></router-outlet>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>


