import { Component } from '@angular/core';
import { Router, } from '@angular/router';

import { ServerService } from '../services/server.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent {
  title = 'Login';
  public username: string;
  public password: string;
  state = "none"
 
  constructor(private server: ServerService, private router: Router) { }

  login() {
    this.state = "logging_in"
    this.server.login(this.username, this.password)
    .then( (data) => {
      console.log(data);
      this.state = "logged_in";
      this.router.navigate(['week'], { replaceUrl: true });
    })
    .catch ( (error) => {
      this.state = "error";
      console.error(error.error);
    });
  }

}
