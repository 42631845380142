import { Component, OnInit } from '@angular/core';
import { Router, } from '@angular/router';

import { ServerService } from '../services/server.service';
import { ITask } from './week.component';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html'
})
export class TasksComponent implements OnInit {
  editing = false;
  working = false;
  loading = true;
  taskList: ITask[] = [];
  taskList_backup = [];

  constructor(private server: ServerService, private router: Router) { }

  ngOnInit() {
    this.getAllTasks();
  }

  async getAllTasks() {
    this.working = true;
    await this.server.getTasks()
    .then( (data) => {
      this.taskList = data as ITask[];
      this.taskList_backup = JSON.parse(JSON.stringify(this.taskList));
    })
    .catch ( (error) => {
      console.error(error.error);
      if (error.error == "Not logged in") {
        this.router.navigate(['login'], { replaceUrl: true });
      }
    });
    this.working = false;
  }

  edit = function() {
    this.editing = true;
  };

  cancel = function() {
    this.editing = false;
    this.taskList = JSON.parse(JSON.stringify(this.taskList_backup));
  };

  add_task = function() {
    this.taskList.push({"parent": null, "active": true});
    this.edit();
  };

  async save() {
    this.editing = false;
    this.working = true;

    await this.server.saveTasks(this.taskList)
    .catch ( (error) => {
      this.editing = true;
      this.working = false;
    });

    this.working = false;
    this.taskList_backup = JSON.parse(JSON.stringify(this.taskList));
  };


}
