import { Component, OnInit } from '@angular/core';
import { Injectable, PipeTransform, Pipe } from '@angular/core';
import { Router, } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

import { ServerService } from '../services/server.service';

export interface IUser {
  username: string,
  fname: string,
  lname: string,
  email: any
}

export interface IActivity {
  id: number,
  time: any,
  task_id: number
}

export interface IWorkPass {
  id: number,
  date: any,
  activity: IActivity[],
  start: any,
  end: any
}

export interface IDay {
  name: string,
  date: string,
  work_hours: number,
  work: IWorkPass[],
  holiday: boolean,
  totalTime: number,
  break: number,
  flex: number
}

export interface IWeekData {
  year: number,
  week: number,
  days: IDay[],
  flex_in: number,
  flex_out: number,
}

export interface ITask {
  id: number,
  name: string,
  description: string,
  parent: any,
  active: boolean
}

@Component({
  selector: 'app-week',
  templateUrl: './week.component.html'
})
export class WeekComponent implements OnInit {
  state = "none"
  weekData: IWeekData;
  week_backup: IWeekData;
  year: number;
  week: number;
  editing = false;
  working = false;
  loading = true;
  weekDate = new Date();
  pipe = new DatePipe('sv');
  usedTasks: number[] = [];
  taskList: ITask[] = [];

  user: IUser = { fname: '', lname: ''} as IUser;

  constructor(private server: ServerService, private router: Router) { }

  getWeekNumber( theDate: Date) {
    const onejan = new Date(theDate.getFullYear(), 0, 1);
    const diff = theDate.getTime() - onejan.getTime();
    return Math.ceil ((diff / 86400000) + onejan.getDay() || 7) / 7;
  }

  ngOnInit() {
    this.server.getCurrentUser()
      .then( (data) => {
        this.user = data[0] as IUser;
      });
    var day = this.weekDate.getDay();
    this.weekDate.setDate(this.weekDate.getDate()+(4-day));
    
    this.year = this.weekDate.getFullYear();
    this.week = parseInt(this.pipe.transform(this.weekDate, 'w'));
    this.weekData = {year: this.year, week: this.week, days:[], flex_in: 0, flex_out: 0};
    this.getWeek(this.year, this.week);
    this.getAllTasks();
  }

  async getWeek(year, week) {
    this.working = true;
    const workDate = new Date(this.weekDate);
    var day = workDate.getDay();
    workDate.setDate(this.weekDate.getDate()+(day-7));
    await this.server.getWeek(workDate, year, week)
    .then( (data) => {
      console.log(data);
      this.weekData = data as IWeekData;
      this.year = year;
      this.week = week;
      this.fixNamesAndDates(this.weekDate, this.weekData.days);
      this.week_backup = JSON.parse(JSON.stringify(this.weekData)) as IWeekData;
      this.populateUsedTasks(this.weekData);
      this.add_used_task(-1);
      this.calc_total_time();
      this.loading = false;
    })
    .catch ( (error) => {
      console.error(error.error);
      if (error.error == "Not logged in") {
        this.router.navigate(['login'], { replaceUrl: true });
      }
    });

    this.working = false;
  }

  async getAllTasks() {
    this.working = true;
    await this.server.getTasks()
    .then( (data) => {
      const task = {"id": -1, "name":"-- Choose project --", "parent": null, "description": "", "active": true} as ITask;
      this.taskList = [task];
      this.taskList = this.taskList.concat(data as ITask[]);
    })
    .catch ( (error) => {
      console.error(error.error);
      if (error.error == "Not logged in") {
        this.router.navigate(['login'], { replaceUrl: true });
      }
    });
    this.working = false;
  }

  change_task(index, newTaskId) {
    newTaskId = parseInt(newTaskId.target.value);

    this.weekData.days.forEach(day => {
        day.work.forEach(work_pass => {
          work_pass.activity[index].task_id = newTaskId;
        });
    });


    if (index==this.usedTasks.length - 1) {
      this.add_used_task(-1);
    }

    this.usedTasks[index] = newTaskId;
  }

  filterTasks = function(task, selectedTaskId) {
    if (task.id >= 0 && !task.active && selectedTaskId!=task.id)
        return false;

    let result = task.id >= 0;
    result = result || selectedTaskId < 0;

    const used = this.usedTasks.indexOf(task.id) >= 0;
    result = result && (!used || selectedTaskId==task.id);
    return result;
  }

  populateUsedTasks(week: IWeekData) {
    this.usedTasks = [];
    week.days.forEach(day => {
      day.work.forEach(work_pass => {
          work_pass.activity.forEach(activity => {
              if (!this.usedTasks.includes(activity.task_id))
                  this.usedTasks.push(activity.task_id);
          });
      });
    });
  }

  fixNamesAndDates(date: Date, days: IDay[]) {
    // Set date to first day of week
    const workDate = new Date(date);
    var day = workDate.getDay();
    workDate.setDate(date.getDate()+(day-7));
    days.forEach(day => {
      day.date = this.pipe.transform(workDate, "d/M");
      day.name = this.pipe.transform(workDate, "EEE");
      workDate.setDate(workDate.getDate()+1);
    });
  }

  getCurrentUser() {
    this.server.getCurrentUser()
    .then( (data) => {
      console.log(data);
      this.user = data as IUser;
    })
    .catch ( (error) => {
      this.state = "error";
      console.error(error.error);
    });
  }

  change_week(change) {
    this.weekDate.setDate(this.weekDate.getDate()+change*7);
    this.select_date(this.weekDate);
//    this.select_date(null, this.weekDate.getFullYear(), this.pipe.transform(this.weekDate, 'w'));
  }

  select_date = function(newDate) {
    //date = $scope.weekdate;
    var day = newDate.getDay();
    //    const currDay = parseInt(this.pipe.transform(this.weekDate, 'd'));
    newDate.setDate(newDate.getDate()+(4-day));
    const year = newDate.getFullYear();
    const week = this.pipe.transform(newDate, 'w');
    if ((week != this.week_backup.week) || (year != this.week_backup.year)) {
        this.editing = false;
        this.working = true;
        this.weekDate = newDate;
        this.getWeek(year, week);
    }
  };

  edit = function() {
    this.editing = true;
    this.week_backup = JSON.parse(JSON.stringify(this.week));
  };

  cancel = function() {
    this.editing = false;
    this.week = JSON.parse(JSON.stringify(this.week_backup));
  };

  save = function() {
    this.editing = false;
    this.working = true;

    this.server.saveWeek(this.weekData)
    .then( (response) => {
        //  this.getWeek(this.year, this.week);
    })
    .catch ( (error) => {
      this.state = "error";
      console.error(error.error);
      this.editing = true;
      this.working = false;
    });

    // Update week
    this.working = false;
  };

  export = function () {
    // Build data
    const data = [];
    data.push(['Datum', 'Arbete', 'Moment', 'Tid']);
    this.weekData.days.forEach(day => {
        day.work.forEach(work_pass => {
            if ("activity" in work_pass)
                work_pass.activity.forEach(activity => {
                    if (activity.time > 0) {
                        const index = this.taskList.findIndex(function(task) {return task.id == activity.task;});
                        let parent: any;
                        let child: any;
                        if (this.taskList[index].parent > 0)
                        {
                            const self = this;
                            const parent_index = this.taskList.findIndex(function(task) {
                              return task.id == self.taskList[index].parent;
                            });
                            parent = this.taskList[parent_index].name
                            child = this.taskList[index].name;
                        } else {
                            parent = this.taskList[index].name;
                            child = this.taskList[index].name;
                        }
                        let work = [day.date, parent, child, activity.time];
                        data.push(work);
                    }
                });
        });
    });
    //data = [["Tomas Wiell", "2019-04-01", "Service", "Not required", "", 5.5]]

    var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Time");
    XLSX.writeFile(wb, this.user.fname + "_" + this.user.lname + "_Tidsrapport_" + this.year + "_W" + this.week + ".xlsx");

};

  calc_total_time = function() {
    this.weekData.flex_out = this.weekData.flex_in;
    this.weekData.days.forEach(day => {
        day.total_time = 0;
        day.break = 0;
        day.flex = 0;
        if ("work" in day)
        {
            day.work.forEach(work_pass => {
                work_pass.total_time = 0;
                if ("activity" in work_pass) {
                    work_pass.activity.forEach(activity => {
                        work_pass.total_time = work_pass.total_time + activity.time;
                    });
                    if ((work_pass.end!=undefined) && (work_pass.start!=undefined))
                        work_pass.break = work_pass.end - work_pass.start - work_pass.total_time;
                    day.total_time = day.total_time +  work_pass.total_time
                }
            });
            if (day.work_hours != undefined)
                day.flex = day.total_time - day.work_hours;
            else
                day.flex = day.total_time;
            this.weekData.flex_out = this.weekData.flex_out + day.flex;
        }
    });
  };

  add_work_pass = function(day: any) {
    if (!this.working && this.editing) {
      const newPass = {"start": null, "end": null,  "activity":[]};
      this.usedTasks.forEach(taskId => {
          newPass.activity.push({'task': taskId, 'time': null});
      });
      day.work.push(newPass);
    }
  }

  add_used_task = function(id) {
    this.usedTasks.push(id);
    this.weekData.days.forEach(day => {
        day.work.forEach(work_pass => {
            work_pass.activity.push({"task": id, "time": null});
        });
    });       
  };
  
  set_input_width(day: IDay) {
    if ((day.work == null) || (day.work.length == 1))
      return '75px';
    else
      return '35px';
  }
  
  calc_column_width(day) {
    var width = 80;
    if ((day.work != null) && (day.work.length > 1))
      width = day.work.length * 43;
    return width.toString() + 'px';
  }

  is_active(id) {
    const task = this.taskList.find(task => task.id==id);
    if (task == undefined)
        return false;
    else
        return (id < 0) || task.active;
  }

}

@Pipe({
  name: 'filterTasks',
  pure: false
})
export class FilterTasks implements PipeTransform {
  transform(taskList:ITask[], selectedTaskId: number, self: WeekComponent) {
      return taskList.filter( task => self.filterTasks(task, selectedTaskId));
  }
}

@Pipe({
  name: 'callback'
})
export class CallbackPipe implements PipeTransform {
  transform(items: any[], callback: (item: any) => boolean): any {
      if (!items || !callback) {
          return items;
      }
      return items.filter(item => callback(item));
  }
}

