import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';

import { registerLocaleData } from '@angular/common';
import localeSv from '@angular/common/locales/sv';
import { LOCALE_ID } from '@angular/core';

registerLocaleData(localeSv);

import { LoginComponent } from './pages/login.component';
import { WeekComponent } from './pages/week.component';
import { TasksComponent } from './pages/tasks.component';
import { FilterTasks } from './pages/week.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    WeekComponent,
    TasksComponent,
    FilterTasks,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [{provide: LOCALE_ID, useValue: 'sv'}],
  bootstrap: [AppComponent]
})
export class AppModule { }
