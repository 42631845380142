import { Component, OnInit } from '@angular/core';
import { ServerService } from './services/server.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'Tidsrapportering';

  constructor(private server: ServerService) { 

  }

  ngOnInit() {
//    this.server.getWeek2()
  }

  login() {
    this.server.login("towi", "kkckkcackc")
    //return "Testar";
  }

  getWeek() {
    this.server.getWeek(null, 2019, 10)
    //return "Testar";
  }

}
