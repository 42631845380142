<section class="dialog_container dialog_content_float" style="resize:both;overflow: auto;margin-left: 20px; margin-top:20px;">
  <fieldset class="dialog_field">
      <legend>Aktiviteter</legend>
      <fieldset [disabled]="!editing">           
           <table style="width:100%" class="input_field" >
              <thead>
                  <tr>
                      <th style="text-align: left">Id</th>
                      <th style="text-align: left">Title</th>
                      <th style="text-align: left">Type</th>
                      <th style="text-align: left">Active</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let x of taskList">
                      <td>{{ x.id }}</td>
                      <td><input type="text" [(ngModel)]="x.name"></td>
                      <td>
                          <select [(ngModel)]="x.parent" >
                              <option value="null">None</option>
                              <option *ngFor="let task of taskList" [ngValue]="task.id">{{task.name}}</option>
                          </select>
                      </td>
                      <td><input type="checkbox" [(ngModel)]="x.active" [checked]="x.active || x.active==1"></td>
                  </tr> 
              </tbody>
          </table>
      </fieldset>

      <div class="dialog_field">
          <button (click)="edit()" [disabled]="editing || working">Edit</button>
          <button (click)="save()" [disabled]="!editing || working">Save</button>
          <button (click)="cancel()" [disabled]="!editing || working">Cancel</button>
          <button (click)="add_task()" [disabled]="working">New</button>
      </div>
  </fieldset>
</section>